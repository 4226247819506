import { useState } from "react";
import './Home.css';

const initOutput = {
  title: "Ponniyin.Selvan.2022",
  lang: "Tamil",
  resolution: "1080p",
  site: "AMZN",
  releaser: "tmp0x",
  codec: "AVC.AAC",
  output: "",
};

const Home = () => {
  const [output, setOutput] = useState(initOutput);

  const copyOutput = () => {
    navigator.clipboard.writeText(getOutput(output));
  }

  const titleOnChange = (e) => {
    let val = e.target.value
      .replace(/[^a-zA-Z0-9]/g, " ")
      .trim()
      .replace(/\s+/g, ".");
    console.log(val);
    setOutput({ ...output, title: val });
  };

  const getOutput = (output) => {
    const { title, lang, resolution, site, releaser,codec } = output;
    let newTxt = `${title}.${lang}.${resolution}.${site}.${codec}`;
    newTxt = newTxt.replace(/[^a-zA-Z0-9]/g, " ")
    .trim()
    .replace(/\s+/g, ".");
    newTxt += `-${releaser}.mkv`;
    return newTxt;
  };

  const resolutionOnChange = (e) => {
    let val = e.target.value;
    console.log(val);
    setOutput({ ...output, resolution: val });
  };

  const siteOnChange = (e) => {
    let val = e.target.value;
    console.log(val);
    setOutput({ ...output, site: val });
  };

  const langOnChange = (e) => {
    let val = e.target.value;
    console.log(val);
    setOutput({ ...output, lang: val });
  };

  const releaserOnChange = (e) => {
    let val = e.target.value;
    console.log(val);
    setOutput({ ...output, releaser: val });
  }

  const codecOnChange = (e) => {
    let val = e.target.value;
    console.log(val);
    setOutput({ ...output, codec: val });
  }

  return (
    <div class="container">
      <form>
        <div class="form-group item-container">
          <label>TITLE</label>
          <input
            type="text"
            class="form-control"
            onChange={titleOnChange}
            placeholder="Ponniyin Selvan 2022"
          />
        </div>
        <div class="form-group item-container">
          <label>LANGUAGE:</label>
          <input
            type="text"
            onChange={langOnChange}
            class="form-control"
            placeholder="Tamil"
          />
        </div>
        <div class="item-container">
          <label>RES</label>
          <select class="form-control" onChange={resolutionOnChange}>
            <option value="1080p">1080p</option>
            <option value="2160p">2160p</option>
            <option value="720p">720p</option>
            <option value="576p">576p</option>
          </select>
        </div>
        <div class="item-container">
          <label>SITE:</label>
          <input
            type="text"
            onChange={siteOnChange}
            class="form-control"
            placeholder="AMZN"
          />
        </div>
        <div class="item-container">
          <label>CODEC:</label>
          <input
            type="text"
            onChange={codecOnChange}
            class="form-control"
            placeholder="x264 AAC"
          />
        </div>
        <div class="item-container">
          <label>RELEASER:</label>
          <input
            type="text"
            onChange={releaserOnChange}
            class="form-control"
            placeholder="tmp0x"
          />
        </div>
        <div class="item-container output-name">
          <label>OUTPUT:</label>
          <p>{getOutput(output)}</p>
          <div>
            <button type="button" class="btn btn-primary" onClick={copyOutput}>
              COPY
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Home;
